import axios from 'axios'
const service = axios.create({
    // baseURL: "http://192.168.66.9:5005/api/", 
    baseURL: window.location.origin + "/api/",
    // baseURL: "/api",
    timeout: 50000, // 请求超时时间
    withCredentials: true, // 允许携带cookie
})
service.interceptors.request.use((config) => {
    // config.headers.token = ''
    console.log(config);
    
    return config
}, (err) => {
    // console.log('a1')
    return Promise.reject(err)
})

service.interceptors.response.use((res) => {
    // const response = res.data
    console.log(res);
    
    return res
}, (err) => {
    console.log('a')
    return Promise.reject(err)
})

export default service