import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    if (key.startsWith('ElIcon')) {
        app.component(key, component)
    } 
}
createApp(App).use(router).use(ElementPlus).mount('#app')
 