
import { defineComponent,ref,reactive } from 'vue'; 
// import HelloWorld from '@/components/HelloWorld.vue'; // @ is an alias to /src
import { ElMessage } from 'element-plus'
import { login } from '@/api/research/index'
export default defineComponent({
  name: 'HomeView',
  components: {
    // HelloWorld,
  },
  setup() {
    var token = ref('')
    var imagebase = ref('')  
    var uid = ref('')
    var clickshow = ref(false)
    var timer:any = null
    const getqr = () => {
      clickshow.value = false
      login({
        type:'get'
      }).then(res=>{
        if(res.data.code != 422){
          imagebase.value = res.data.base64,
          uid.value = res.data.uid
          // 每五秒请求一次 getqrst
          if(imagebase.value !='' && uid.value !=''){
            timer = setInterval(()=>{
              getqrst() 
            },5000)
          }
        }
       
        
      })
    }
    const getqrst = () => {
      login({
        type:'check',
        uid:uid.value
      }).then(res=>{
        console.log(res)
        if(res.data.data.status == 1){
          clickshow.value = true
          clearInterval(timer)
        }else if(res.data.data.status == 2){
          ElMessage({
            message: '扫码成功',
            type: 'success'
          })
          token.value = res.data.data.token
          // clickshow.value = false
          clearInterval(timer)
        }
      })
    }
    getqr()
    return {
      imagebase,
      uid,
      clickshow,
      token,
      getqr
    };
  },
});
