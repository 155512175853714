import request from '@/utils/request';
import qs from 'qs';
/**
 * 用户登录
 */
 export function login(data: any) {
    return request({
        url: 'auth/weblogin/',
        method: 'get',
        // data,
        params: data,
    })
}

/**
 * IP登录
 */
export function iplogin() {
    return request({
        url: 'iplogin/',
        method: 'get',
    })
}

